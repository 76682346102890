import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { usersApi } from "../../redux/apis/usersApi";

const SuperAdminOrAdminRoute = ({ children }) => {
  const email = localStorage.getItem("email");
  const { data: userData, isLoading } = usersApi.useGetUserByEmailQuery(email);

  const currentUser = userData?.data;
  
  const location = useLocation();
  if ( isLoading) {
    return <progress className="progress w-56"></progress>;
  }

  const isSuperAdminOrAdminRoute = currentUser?.role === "Admin" || currentUser?.role === "Super Admin";



  if (isSuperAdminOrAdminRoute) {
    return children;
  } else {
    return <Navigate to="/login" state={{ from: location }} replace></Navigate>;
  }
   
  

};

export default SuperAdminOrAdminRoute;

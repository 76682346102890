import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'
import { categoryApi } from '../../redux/apis/categoryApi'


const Pilars = (props) => {

    const { data: eresourceTypesData, isLoading } = categoryApi.useGetCategoriesByTypeQuery("e-resources")

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

     if(isLoading){
        return <p>Loading..</p>
     }

     const eresourceTypes = eresourceTypesData?.data;
     
     

    return(
        <div className="pilars-area">
            <div className="fluid-container">
                <div className="row-grid">
                    {eresourceTypes?.map((pillar) =>(
                        <div className="grid" key={pillar?._id}>
                            <div className="pilars-item">
                                <div className="pilarts-img">
                                    <img src={pillar?.image} alt="" />
                                </div>
                                <div className="pilars-text">
                                    <h2><Link onClick={ClickHandler} to={`/${pillar?.slug}/${pillar?._id}`}>{pillar?.name}</Link></h2>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Pilars;
import React from "react";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdOutlineDeleteForever } from "react-icons/md";
import Swal from "sweetalert2";
import { pagesApi } from "../../../redux/apis/pagesApi";

const AllPages = () => {
   const { data: pagesData, isLoading, refetch } = pagesApi.useGetAllPagesQuery();
   const [ deletePage ] = pagesApi.useDeletePageMutation();

   const pages = pagesData?.data || [];


   const handleDeletePage = async (id, pageTitle) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert ${pageTitle}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePage(id).then((res) => {
          if (res.data)
            refetch();
            Swal.fire({
              title: "Deleted!",
              text: `${pageTitle} has been deleted `,
              icon: "success",
            });
        });
      }
    });
  };

    return (
        <div className=" container mt-5">
          <div className=" flex justify-between  md:px-4 mb-3 ">
            <h3 className=" text-xl font-semibold">All pages</h3>
            <Link to={`/dashboard/add-page`} className=" px-2 py-1 bg-blue-500 hover:bg-blue-700 rounded text-white text-[16px]">Add New page</Link>
          </div>
             <div className="container overflow-x-auto">
               <table className="table table-zebra">
                 {/* head */}
                 <thead>
                   <tr>
                     <th>Si</th>
                     <th>Title</th>
                     <th>Link</th>
                     <th>Action</th>
                   </tr>
                 </thead>
                 <tbody>
                   {/* row 1 */}
             
                   {
                     (!isLoading && pages) ?   (pages?.map((page, i) =>  (<tr key={page?._id}>
                     <th>{i + 1}</th>
                     <td>{page?.title}</td>
                     <td>{page?.link}</td>
                     
                     {
                      i > 4 &&  <td className=" flex gap-2">
                      <Link to={`/dashboard/edit-page/${page?._id}`}> <FaEdit className=" text-green-500 text-xl "/> </Link>
                      <MdOutlineDeleteForever onClick={( ) => handleDeletePage(page?._id, page?.title)} className=" text-rose-600 text-xl"/>
                    </td>
                     }
        
                   </tr>)))  
                   : 
                   <p>pages not found</p>
                   }
             
                 </tbody>
               </table>
             </div>      
          </div>
  );
};

export default AllPages;
import React from 'react';
import { Link } from 'react-router-dom';
import { FaQuran, FaMosque, FaHandsHelping, FaPrayingHands, FaBook } from 'react-icons/fa'; // Example icons
import './style.css';
import { bannerApi } from '../../redux/apis/bannerApi';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

const Hero = (props) => {
  const id = "675131ea5ba69e9df67960fd";
  const { data: bannerData } = bannerApi.useGetBannerByIdQuery(id);
  const banner = bannerData?.data || {};

      // Convert description from Draft.js JSON to HTML
      let descriptionHTML = "";
      try {
        const contentState = convertFromRaw(JSON.parse(banner?.description));
        descriptionHTML = stateToHTML(contentState);
      } catch (error) {
        console.error("Error parsing description:", error);
        descriptionHTML = "<p>Description could not be loaded.</p>";
      }

  return (
    <section className={`hero ${props.HeroStyleClass}`}>
      <div className="hero-slider">
        <div className="slide">
          <div className="container">
            <div className="row">
              <div className="col col-lg-7 slide-caption">
                <div className="slide-top">
                  <span>{banner?.topic}</span>
                </div>
                <div className="slide-title">
                  <h2>{banner?.title}</h2>
                </div>
                <div className="slide-subtitle">
                <div
                  className="mt-2"
                  dangerouslySetInnerHTML={{ __html: descriptionHTML }}
                /> 
                </div>
                <div className="btns">
                  <Link to="/about" className="theme-btn">Discover More</Link>
                </div>
              </div>
            </div>
          </div>
          <div className="right-vec">
            <img src={"https://ummah-reactjs.wpocean.com/static/media/img-3.44bb17a6.png"} alt="" />
            <div className="right-border">
              <div className="right-icon flex items-center justify-center"><FaQuran size={24} /></div>
              <div className="right-icon flex items-center justify-center"><FaMosque size={24} /></div>
              <div className="right-icon flex items-center justify-center"><FaPrayingHands size={24} /></div>
              <div className="right-icon flex items-center justify-center"><FaHandsHelping size={24} /></div>
              <div className="right-icon flex items-center justify-center"><FaBook size={24} /></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle'
import EventSection2 from '../../components/event2'


const EventPage =() => {
    return(
        <Fragment>
            
            <PageTitle pageTitle={'Our Event'} pagesub={'Event'}/> 
         
            <EventSection2 EventClass={'wpo-event-area-2'}/>
            
        </Fragment>
    )
};
export default EventPage;

import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle'
import BlogLeft from '../../components/BlogLeft'



const BlogPageLeft =() => {
    return(
        <Fragment>
            
            <PageTitle pageTitle={'Latest News'} pagesub={'Blog'}/> 
            <BlogLeft/>
            
        </Fragment>
    )
};
export default BlogPageLeft;


import React from "react";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";

const PageHeader = ({ pageTitle }) => {
  return (
    <div className=" mt-5">


      <div className=" border-[1px] border-gray-400 border-x-0 border-t-0 pb-3">
        <h2 className=" text-2xl font-semibold mb-2">{pageTitle}</h2>
  
         <span className=" flex gap-2 items-center text-gray-500 dark:text-gray-200">
          <Link  to={"/"}> <FaHome />          </Link>
          <span>{">"}</span>
          {pageTitle}
        </span>
      </div>
    </div>
  );
};

export default PageHeader;

import React, { useState } from "react";
import { usersApi } from "../../../redux/apis/usersApi";
import Modal from "./Modal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const AllStaff = () => {
  const { data: users, error, isLoading, refetch } = usersApi.useGetAllUsersQuery();
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [ updateUser ] = usersApi.useUpdateUserMutation();
  const [ deleteUsers ] = usersApi.useDeleteUsersMutation();
  const navigate = useNavigate();

   if(isLoading) {
    return <p>Loading..</p>
   }

   console.log(error)

   const allUsers = users?.data?.users || [];


     // handle user role change
  const handleRoleChange = async ( role) => {
    // Here you can implement the role change logic
    try{
      const res = await updateUser({ id: currentUser?._id, data: { role } }).unwrap(); // Unwrap the response
      if (res?.success) {
        refetch();
        toast.success(res?.data?.message || "Role updated successfully!");
      }
    } catch(err){
      toast.error(err?.data?.message)
    }
  };

  
  const handleDeleteUser = async (id, userName) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert ${userName}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUsers(id).then((res) => {
          if (res.data)
            refetch();
            Swal.fire({
              title: "Deleted!",
              text: `${userName} has been deleted `,
              icon: "success",
            });
        });
      }
    });
  };

    return (
        <div className=" container">
           <h2 className=" text-center text-2xl mt-4 underline"> All User list</h2>
            <div className="container overflow-x-auto">
  <table className="table table-zebra">
    {/* head */}
    <thead className="w-full">
      <tr>
        <th>#</th>
        <th>id</th>
        <th>Photo</th>
        <th>Name</th>
        <th>Email</th>
        <th>Role</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody className=" w-full">
      {/* row 1 */}
       {
        allUsers?.length > 0 ? allUsers?.map((user, i) => (
          <tr key={user?._id}>

          <th>{i + 1}</th>
          <th>{user?.id}</th>
          <td><img src={user?.profilePicture} alt="img" className=" w-14 h-14" /></td>
          <td>{user?.name}</td>
          <td>{user?.email}</td>
          <td>{user?.role}</td>
          <td>
            <select name="" id=""
                  onChange={(e) => {
                    if (e.target.value === "role-change") {
                      setCurrentUser(user);
                      setModalOpen(true);
                    } else if (e.target.value === "delete") {
                      handleDeleteUser(user?._id, user?.name);
                    } else if (e.target.value === "view") {
                      navigate(`/dashboard/user-profile/${user?.email}`);
                    }
                  }}
            >
           
              <option className=" px-2 py-1" value="">Options</option>
              <option className=" px-2 py-1" value="role-change">Role change</option>
              <option className=" px-2 py-1" value="delete">Delete</option>
            </select>
          </td>
        </tr>
        ))
      : 
       <p className=" text-center mt-20">Users not found</p>
       }
    </tbody>
  </table>
</div>
      <Modal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleRoleChange}
        />  
        </div>
    );
};

export default AllStaff;
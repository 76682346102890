import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Main Pages
import Homepage from '../HomePage';
import AboutPage from '../AboutPage';
// import ServicePage from '../ServicePage';
import ErecoursePage from '../Eresources';
import EventPage from '../EventPage';
import EventPageSingle from '../EventPageSingle';
import DonatePage from '../DonatePage';
import BlogPage from '../BlogPage';
import BlogDetails from '../BlogDetails';
import BlogPageLeft from '../BlogPageLeft';
import BlogPageFullwidth from '../BlogPageFullwidth';
import ContactPage from '../ContactPage';
import LoginPage from '../LoginPage';
import SignUpPage from '../SignUpPage';
import ForgotPassword from '../ForgotPassword';
import ErrorPage from '../ErrorPage';
import CustomPage from '../CustomPage/CustomPage';

// Dashboard Pages
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import DashboardHome from '../../dashboard/home/DashboardHome';
import AddEvent from '../../dashboard/pages/events/AddEvent';
import AllEvents from '../../dashboard/pages/events/AllEvents';
import UpdateEvent from '../../dashboard/pages/events/UpdateEvent';
import AddBlog from '../../dashboard/pages/blogs/AddBlog';
import AllBlogs from '../../dashboard/pages/blogs/AllBlogs';
import UpdateBlog from '../../dashboard/pages/blogs/UpdateBlog';
import Categories from '../../dashboard/pages/blogs/Categories';
import AddCategory from '../../dashboard/pages/blogs/AddCategory';
import EditCategory from '../../dashboard/pages/blogs/EditCategory';
import SendNewsletter from '../../dashboard/pages/marketing/SendNewsletter';
import Subscribers from '../../dashboard/pages/marketing/Subscribers';
import HeaderSettings from '../../dashboard/pages/websiteSetup/HeaderSettings';
import FooterSetting from '../../dashboard/pages/websiteSetup/FooterSetting';
import AboutSetting from '../../dashboard/pages/websiteSetup/AboutSetting';
import BannerSetting from '../../dashboard/pages/websiteSetup/BannerSetting';
import AllPages from '../../dashboard/pages/websiteSetup/AllPages';
import UpdatePage from '../../dashboard/pages/websiteSetup/UpdatePage';
import Appearance from '../../dashboard/pages/websiteSetup/Appearance';
import GeneralSettings from '../../dashboard/pages/setupAndConfiguration/GeneralSettings';
import SMTPsettings from '../../dashboard/pages/setupAndConfiguration/SMTPsettings';
import PaymentMethods from '../../dashboard/pages/setupAndConfiguration/PaymentMethods';
import SocailMediaLogin from '../../dashboard/pages/setupAndConfiguration/SocailMediaLogin';
import AllStaff from '../../dashboard/pages/staff/AllStaff';
import StaffPermission from '../../dashboard/pages/staff/StaffPermission';
import UploadPdf from '../../dashboard/pages/e-resources/UploadPdf';
import AllPdf from '../../dashboard/pages/e-resources/AllPdf';
import EditPdf from '../../dashboard/pages/e-resources/EditPdf';

// Utility
import MainLayout from '../../layouts/MainLayout';
import SuperAdminOrAdminRoute from './SuperAdminOrAdminRoute';

// Main Routes
const mainRoutes = [
  { path: "/", element: <Homepage /> },
  { path: "/:slug", element: <CustomPage /> },
  { path: "/about", element: <AboutPage /> },
  // { path: "/service", element: <ServicePage /> },
  { path: "/:slug/:id", element: <ErecoursePage /> },
  { path: "/event", element: <EventPage /> },
  { path: "/single-event/:id", element: <EventPageSingle /> },
  { path: "/donate", element: <DonatePage /> },
  { path: "/blog", element: <BlogPage /> },
  { path: "/blog/:id", element: <BlogDetails /> },
  { path: "/blog-left", element: <BlogPageLeft /> },
  { path: "/blog-fullwidth", element: <BlogPageFullwidth /> },
  { path: "/contact", element: <ContactPage /> },
];

// Dashboard Routes
const dashboardRoutes = [
  { path: "", element: <DashboardHome /> },
  { path: "categories", element: <Categories /> },
  { path: "add-category", element: <AddCategory /> },
  { path: "edit-category/:id", element: <EditCategory /> },
  { path: "all-events", element: <AllEvents /> },
  { path: "add-event", element: <AddEvent /> },
  { path: "update-event/:id", element: <UpdateEvent /> },
  { path: "all-posts", element: <AllBlogs /> },
  { path: "add-blog", element: <AddBlog /> },
  { path: "update-blog/:id", element: <UpdateBlog /> },
  { path: "newsletter", element: <SendNewsletter /> },
  { path: "subscribers", element: <Subscribers /> },
  { path: "header", element: <HeaderSettings /> },
  { path: "footer", element: <FooterSetting /> },
  { path: "about-setting", element: <AboutSetting /> },
  { path: "banner-setting", element: <BannerSetting /> },
  { path: "pages", element: <AllPages /> },
  { path: "edit-page/:id", element: <UpdatePage /> },
  { path: "appearance", element: <Appearance /> },
  { path: "general-setting", element: <GeneralSettings /> },
  { path: "smtp-setting", element: <SMTPsettings /> },
  { path: "payment-methods", element: <PaymentMethods /> },
  { path: "social-media-login", element: <SocailMediaLogin /> },
  { path: "all-staff", element: <AllStaff /> },
  { path: "staff-permission", element: <StaffPermission /> },
  { path: "upload-pdf", element: <UploadPdf /> },
  { path: "all-pdf", element: <AllPdf /> },
  { path: "edit-eresurece/:id", element: <EditPdf /> },
];

// Router Configuration
const router = createBrowserRouter([

  // main routes
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: mainRoutes,
  },

  // auth routes
  { path: "/login", element: <LoginPage /> },
  { path: "/signup", element: <SignUpPage /> },
  { path: "/forgot-password", element: <ForgotPassword /> },

  // dashboard routes
  {
    path: "/dashboard",
    errorElement: <ErrorPage />,
    element: (
      <SuperAdminOrAdminRoute>
        <DashboardLayout />
      </SuperAdminOrAdminRoute>
    ),
    children: dashboardRoutes,
  },
]);

const AppRoutes = () => <RouterProvider router={router} />;

export default AppRoutes;

import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle'
import EventSingle from '../../components/EventSingle'
import { useParams } from 'react-router-dom';
import { eventsApi } from '../../redux/apis/eventsApi';



const EventPageSingle =() => {
    const { id } = useParams();
    const { data: eventData, isLoading } = eventsApi.useGetEventByIdQuery(id);

    if(isLoading){
        return <p>Loading..</p>
    };

    const event = eventData?.data;
    console.log(eventData)

   

    return(
        <Fragment>
            
            <PageTitle pageTitle={'Single Event'} pagesub={`${event?.title}`}/> 
            <EventSingle event={event}/>
            
        </Fragment>
    )
};
export default EventPageSingle;

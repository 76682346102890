import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { pagesApi } from '../../../redux/apis/pagesApi';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";

const UpdatePage = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { id } = useParams();
  const { data: pageData, refetch } = pagesApi.useGetPageByIdQuery(id);
  const [ updatePage ] = pagesApi.useUpdatePageMutation();
  const page = pageData?.data;

  const [editorState, setEditorState] = useState(() => {
    if (page?.content) {
      try {
        const contentState = convertFromRaw(JSON.parse(page.content));
        return EditorState.createWithContent(contentState);
      } catch (error) {
        console.error("Error parsing content:", error);
        return EditorState.createEmpty();
      }
    }
    return EditorState.createEmpty();
  }); 

  
  const onEditorStateChange = (newState) => {
    setEditorState(newState);
  };


  // Handle form submission
  const handleUpdatePage = async(data) => {

    const rawContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));

    data.content = JSON.stringify(rawContent);

    const res = await updatePage({ id, data});

    console.log(res)

    refetch()

    console.log(data); 
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg">
        <header className="px-6 py-4 border-b border-gray-200">
          <h1 className="text-2xl font-semibold text-gray-800">Update  Page</h1>
        </header>
        <form onSubmit={handleSubmit(handleUpdatePage)} className="p-6 space-y-6">
          {/* Page Content */}
          <div>
            <h2 className="text-lg font-medium text-gray-700 mb-4">Page Content</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Title *</label>
                <input
                  type="text"
                  {...register("title", { required: "Title is required" })}
                  className="w-full px-3 py-2 border-gray-400 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  defaultValue={page?.title}
                />
                {errors.title && <p className="text-red-500 text-sm">{errors.title.message}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Link *</label>
                <div className="flex items-center space-x-2">
                  <span className="text-gray-500">https://aqsfoundation.org/</span>
                  <input
                    type="text"
                    {...register("link", { required: "link is required" })}
                    className="flex-1 px-3 py-2 border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    defaultValue={page?.link}
                  />
                </div>
                {errors.link && <p className="text-red-500 text-sm">{errors.link.message}</p>}
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Add Content *</label>
                <Editor
                   editorState={editorState}
                   toolbarClassName="border border-gray-300 rounded-t-md p-2"
                   wrapperClassName="border border-gray-300 rounded-md"
                   editorClassName="p-3 min-h-[200px] rounded-b-md focus:outline-none"
                   onEditorStateChange={onEditorStateChange}
                />  
              </div>
            </div>
          </div>

          {/* SEO Fields */}
          <div>
            <h2 className="text-lg font-medium text-gray-700 mb-4">SEO Fields</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Meta Title</label>
                <input
                  type="text"
                  {...register("metaTitle")}
                  className="w-full py-2 border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Meta content</label>
                <textarea
                  {...register("metacontent")}
                  className="w-full h-20 border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                ></textarea>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Keywords</label>
                <input
                  type="text"
                  {...register("keywords")}
                  placeholder="Keyword, Keyword"
                  className="w-full border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Meta Image</label>
                <input
                  type="file"
                  {...register("metaImage")}
                  className="block w-full text-gray-500"
                />
              </div>
            </div>
          </div>

          {/* Submit */}
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-6 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-700"
            >
              Save Page
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePage;

import React from "react";
import DashboardStates from "./DashboardStates";
import EresourcesChart from "./EresourcesChart";
import EventsChart from "./EventsChart";
import BlogsChart from "./BlogsChart";

const DashboardHome = () => {
    return (
        <div className=" container ">
            <DashboardStates />
           <div className=" flex flex-col lg:flex-row gap-2 mb-5">
            <EresourcesChart />
            <EventsChart />
           </div>
           <BlogsChart />
        </div>
    );
};

export default DashboardHome;
import React from 'react';
import { Link } from 'react-router-dom';
import { eventsApi } from '../../redux/apis/eventsApi';
import { FaClock } from 'react-icons/fa';

const EventSection2 = (props) => {
    const { data: eventData, isLoading } = eventsApi.useGetAlleventsQuery();

    if (isLoading) {
        return <p>Loading</p>;
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    const events = eventData?.data?.events || [];

    // Function to format time with AM/PM
    const formatTime = (dateString) => {
        const date = new Date(dateString);
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };
        return date.toLocaleString('en-US', options);
    };

    // Function to extract date and month
    const formatDateAndMonth = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('en-US', { month: 'short' }); // Short month format (e.g., Jan)
        return { day, month };
    };

    return (
        <div className={`wpo-event-area section-padding ${props.EventClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            <span>Our Events</span>
                            <h2>Our Upcoming Events</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {events.map((event) => {
                        const { day, month } = formatDateAndMonth(event?.startDate);
                        return (
                            <div className="col-lg-6 col-sm-12 col-12 custom-grid" key={event._id}>
                                <div className="wpo-event-item">
                                    <div className="wpo-event-img w-full ">
                                        <img src={event?.image} alt="" className=' w-full'/>
                                        {/* Top-left corner date and month */}
                                        <div className="thumb-text">
                                            <span className="date">{day}</span>
                                            <span className="month">{month}</span>
                                        </div>
                                    </div>
                                    <div className="wpo-event-text">
                                        <h2>{event?.title}</h2>
                                        <ul className="text-sm">
                                            <li className="inline-block">
                                                <span className="flex gap-1 items-center">
                                                    <FaClock />
                                                    {formatTime(event?.startDate)} - {formatTime(event?.endDate)}
                                                </span>
                                            </li>
                                            <li>
                                                <i className="fa fa-map-marker"></i>
                                                {event?.location}
                                            </li>
                                        </ul>
                                        <p>{event?.shortDescription}</p>
                                        <Link onClick={ClickHandler} to={`/single-event/${event._id}`}>
                                            Learn More...
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default EventSection2;

import React from "react";
import { Link, useParams } from "react-router-dom";
import BlogSidebar from "../BlogSidebar";
import "./style.css";
import { blogsApi } from "../../redux/apis/blogsApi";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { FaCalendarAlt } from "react-icons/fa"; // Calendar Icon
import { FaUserAlt } from "react-icons/fa"; // User Icon

const BlogDetailsComponent = () => {
  const { id } = useParams();
  const { data: blogData, isLoading } = blogsApi.useGetBlogByIdQuery(id);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  const blog = blogData?.data;

  const formattedDate = new Date(blog?.createdAt).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  // Convert description from Draft.js JSON to HTML
  let descriptionHTML = "";
  try {
    const contentState = convertFromRaw(JSON.parse(blog?.description));
    descriptionHTML = stateToHTML(contentState);
  } catch (error) {
    console.error("Error parsing description:", error);
    descriptionHTML = "<p>Description could not be loaded.</p>";
  }

  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-8 col-12">
            <div key={blog.id} className="wpo-wpo-blog-content clearfix">
              <div className="post">
                <div className="entry-media">
                  <img src={blog?.image} alt="Blog" />
                </div>
                <ul className="entry-meta">
                  <li>
                    <Link to="/blog-details" className=' flex gap-2 items-center'>
                      <FaUserAlt /> By {blog?.authorId?.name}
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog-details" className="flex gap-2 items-center">
                      <FaCalendarAlt /> {formattedDate}
                    </Link>
                  </li>
                </ul>
                <h2>{blog?.title}</h2>
                <p className="text-xl">{blog?.shortDescription}</p>
                <div
                  className="mt-2"
                  dangerouslySetInnerHTML={{ __html: descriptionHTML }}
                />
              </div>
              <div className="tag-share clearfix">
                <div className="tag">
                  <ul>
                    {blog?.tags?.length > 0 &&
                      blog?.tags?.map((tag, index) => (
                        <li key={index}>
                          <Link to={`/blog-details?tag=${tag}`}>{tag}</Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div className="author-box">
                <div className="author-avatar">
                  <Link to="/blog-details" target="_blank">
                    <img
                      src={blog?.authorId?.profilePicture}
                      alt="Author"
                    />
                  </Link>
                </div>
                <div className="author-content">
                  <Link to="/blog-details" className="author-name">
                    {blog?.authorId?.name}
                  </Link>
                  <p>{blog?.authorId?.bio}</p>
                  <div className="author-btn">
                    <Link to="/blog-details">All Post From Author</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BlogSidebar author={blog?.authorId}/>
        </div>
      </div>
    </section>
  );
};

export default BlogDetailsComponent;

import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { dashboardApi } from "../../redux/apis/dashboardApi";

// const data = [
//   { name: "Laptop", stock: 250 },
//   { name: "Gadget", stock: 300 },
//   { name: "Desktop", stock: 200 },
//   { name: "Component", stock: 480 },
//   { name: "Monitor", stock: 100 },
//   { name: "UPS", stock: 320 },
//   { name: "Accessories", stock: 180 },
//   { name: "Office Equipment", stock: 260 },
//   { name: "Security", stock: 270 },
//   { name: "Networking", stock: 250 },
//   { name: "IT Solutions", stock: 230 },
// ];

const EresourcesChart = () => {
    const { data: eresourcesData, isLoading } = dashboardApi.useGetCategoryWiseEresourcesQuery();

    if(isLoading){
        return <p>Loading..</p>
    }

    const data = eresourcesData?.data || [];

  return (
    <div style={{ width: "100%", height: 500 }}>
      <h3 style={{ textAlign: "center", marginBottom: "20px" }} className=" text-xl">
        Category wise eresource chart
      </h3>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 50, // Add margin for slanted labels
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            angle={-45} // Set the angle for slanted labels
            textAnchor="end" // Align text to the end for better appearance
            interval={0} // Ensure all labels are shown
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" fill="#3b82f6" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default EresourcesChart;

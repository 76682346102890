import React from 'react';
import { Link } from 'react-router-dom';
import VideoModal from '../../components/ModalVideo';
import blog1 from '../../images/blog/img-7.jpg';
import blog2 from '../../images/blog/img-9.jpg';
import blog3 from '../../images/blog/img-8.jpg';

const blogPosts = [
    {
        id: 1,
        type: 'image',
        image: blog1,
        author: 'Robert Harry',
        date: '25 Feb 2021',
        title: 'The Importance of Marriage in Islam.',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.',
        likes: 80,
        category: 'Marriage',
    },
    {
        id: 2,
        type: 'gallery',
        image: blog3,
        author: 'Robert Harry',
        date: '25 Feb 2021',
        title: 'Compleate Hajj guide for your Hajj.',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.',
        likes: 80,
        category: 'Health',
    },
    {
        id: 3,
        type: 'video',
        image: blog2,
        author: 'Robert Harry',
        date: '25 Feb 2021',
        title: 'Salat is the best exercise for body fitness',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.',
        likes: 80,
        category: 'Salat',
        video: true,
    },
];

const BlogFullwidth = () => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <section className="wpo-blog-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="wpo-blog-content">
                            {blogPosts.map((post) => (
                                <div className="post format-standard-image" key={post.id}>
                                    <div className="entry-media">
                                        <img src={post.image} alt="" />
                                        {post.video && (
                                            <div className="video-btn2">
                                                <VideoModal />
                                            </div>
                                        )}
                                    </div>
                                    <ul className="entry-meta">
                                        <li>
                                            <Link onClick={ClickHandler} to="/blog-details">
                                                By {post.author}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/blog-details">
                                                {post.date}
                                            </Link>
                                        </li>
                                    </ul>
                                    <h3>
                                        <Link onClick={ClickHandler} to="/blog-details">
                                            {post.title}
                                        </Link>
                                    </h3>
                                    <p>{post.description}</p>
                                    <div className="entry-bottom">
                                        <Link onClick={ClickHandler} to="/blog-details" className="read-more">
                                            View More...
                                        </Link>
                                        <ul>
                                            <li>
                                                <i className="fi flaticon-like"></i>
                                                {post.likes}
                                            </li>
                                            <li>
                                                <i className="fi flaticon-share"></i>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="blog-thumb-badge">
                                        <div className="blog-thumb-text">
                                            <span>{post.category}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="pagination-wrapper pagination-wrapper-left">
                                <ul className="pg-pagination">
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details" aria-label="Previous">
                                            <i className="fi ti-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <Link onClick={ClickHandler} to="/blog-details">
                                            1
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details">
                                            2
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details">
                                            3
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details" aria-label="Next">
                                            <i className="fi ti-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogFullwidth;

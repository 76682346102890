// src/Modal.js
import React from "react";

const Modal = ({ isOpen, onClose, onSave }) => {
  const [selectedRole, setSelectedRole] = React.useState("");

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-1/3">
        <h2 className="text-lg font-semibold mb-4">Change User Role</h2>
        <select
          className="border rounded-md p-2 w-full mb-4"
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
        >
          <option value="">Select</option>
          <option value="Super Admin">Super Admin</option>
          <option value="Admin">Admin</option>  
          <option value="user">User</option>
        </select>
        <div className="flex justify-between">
          <button
            onClick={() => {
              onSave(selectedRole);
              onClose();
            }}
            className="bg-green-500 text-white rounded-md px-4 py-2"
          >
            Save
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-black rounded-md px-4 py-2"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

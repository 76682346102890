import React from 'react';
import { Link } from 'react-router-dom';
import BlogSidebar from '../BlogSidebar';
import VideoModal from '../../components/ModalVideo';
import './style.css';
import blog1 from '../../images/blog/img-7.jpg';
import blog2 from '../../images/blog/img-9.jpg';
import blog3 from '../../images/blog/img-8.jpg';
import { blogsApi } from '../../redux/apis/blogsApi';

const BlogList = () => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };
   const { data: blogsData, isLoading, error } = blogsApi.useGetAllBlogsQuery();

   

   if(isLoading){
    return <p>Loading...</p>
   }

   const blogs = blogsData?.data?.blogs || [];
   const author = blogs[0]?.authorId  || {};

    // Blog post data
    const blogPosts = [
        {
            id: 1,
            image: blog1,
            author: 'Robert Harry',
            date: '25 Feb 2021',
            title: 'The Importance of Marriage in Islam.',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.',
            category: 'Marriage',
            likes: 80,
        },
        {
            id: 2,
            image: blog3,
            author: 'Robert Harry',
            date: '25 Feb 2021',
            title: 'Complete Hajj guide for your Hajj.',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.',
            category: 'Health',
            likes: 80,
        },
        {
            id: 3,
            image: blog2,
            author: 'Robert Harry',
            date: '25 Feb 2021',
            title: 'Salat is the best exercise for body fitness',
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.',
            category: 'Salat',
            likes: 80,
            isVideo: true,
        },
    ];

    return (
        <section className="wpo-blog-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8">
                        <div className="wpo-blog-content">
                            {  blogs?.length > 0  ?  blogs.map((blog) => (
                                <div className="post format-standard-image" key={blog._id}>
                                    <div className="entry-media">
                                        <img src={blog?.image} alt="" />
                                        {blog?.isVideo && (
                                            <div className="video-btn2">
                                                <VideoModal />
                                            </div>
                                        )}
                                    </div>
                                    <ul className="entry-meta">
                                        <li>
                                            <Link onClick={ClickHandler} to={`/blog/${blog?._id}`}>
                                                By {blog?.authorId?.name}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to={`/blog/${blog?._id}`}>
                                                {new Date(blog?.createdAt).toLocaleDateString()}
                                            </Link>
                                        </li>
                                    </ul>
                                    <h3>
                                        <Link onClick={ClickHandler} to={`/blog/${blog?._id}`}>
                                            {blog?.title}
                                        </Link>
                                    </h3>
                                    <p>{blog?.shortDescription}</p>
                                    <div className="entry-bottom">
                                        <Link onClick={ClickHandler} to={`/blog/${blog?._id}`} className="read-more">
                                            View More...
                                        </Link>
                                        {/* <ul>
                                            <li>
                                                <i className="fi flaticon-like"></i>
                                                {blog?.likes}
                                            </li>
                                            <li>
                                                <i className="fi flaticon-share"></i>
                                            </li>
                                        </ul> */}
                                    </div>
                                    <div className="blog-thumb-badge">
                                        <div className="blog-thumb-text">
                                            <span>{blog?.categoryId?.name}</span>
                                        </div>
                                    </div>
                                </div>
                            ) ) :
                            <p>Blogs not found</p>
                            }
                            <div className="pagination-wrapper pagination-wrapper-left">
                                <ul className="pg-pagination">
                                    <li>
                                       
                                        <Link onClick={ClickHandler} to="/blog-details" aria-label="Previous">
                                            <i className="fi ti-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <Link onClick={ClickHandler} to="/blog-details">
                                            1
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details">
                                            2
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details">
                                            3
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details" aria-label="Next">
                                            <i className="fi ti-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <BlogSidebar author={author}/>
                </div>
            </div>
        </section>
    );
};

export default BlogList;

import apiService from "../api-service/api-service";

export const eventsApi = apiService.injectEndpoints({
    endpoints: (builder) => ({
        // create a new user
        createEvent: builder.mutation({
            query: (formData) => ({
                url: `events/create-event?locationFolder=event-images`,
                method: "POST",
                body: formData
            }),
        }),

        // get all events
        getAllevents: builder.query({
            query: () => ({
                url: "events/all-events"
            })
        }),

        // get getUpcommingEvents events
        getUpcommingEvents: builder.query({
            query: () => ({
                url: "events/upcomming-events"
            })
        }),

        // get a spacific single event
        getEventById: builder.query({
            query: (id) => ({
                url: `events/single-event/${id}`
            })
        }),


        // update a event
        updateEvent: builder.mutation({
            query: ({ id, data}) => ({
                url: `events/update/${id}`,
                method: "PATCH",
                body: data
            })
        }),

        // update a event
        updateEventByIdWithImage: builder.mutation({
            query: ({ id, formData}) => ({
                url: `events/${id}/update-image/?locationFolder=event-images`,
                method: "PATCH",
                body: formData
            })
        }),

        // delete a event
        deleteEvent: builder.mutation({
            query: (id) => ({

                url: `events/delete/${id}`,
                method: "DELETE"
            })
        })
    
    }),
})
import React from 'react';
import { Link } from 'react-router-dom';
import BlogSidebar from '../BlogSidebar';
import VideoModal from '../../components/ModalVideo';
import blog1 from '../../images/blog/img-7.jpg';
import blog2 from '../../images/blog/img-9.jpg';
import blog3 from '../../images/blog/img-8.jpg';

const blogs = [
    {
        id: 1,
        format: 'standard-image',
        image: blog1,
        category: 'Marriage',
        title: 'The Importance of Marriage in Islam.',
        author: 'Robert Harry',
        date: '25 Feb 2021',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.',
        likes: 80,
    },
    {
        id: 2,
        format: 'gallery',
        image: blog3,
        category: 'Health',
        title: 'Complete Hajj guide for your Hajj.',
        author: 'Robert Harry',
        date: '25 Feb 2021',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.',
        likes: 80,
    },
    {
        id: 3,
        format: 'video',
        image: blog2,
        category: 'Salat',
        title: 'Salat is the best exercise for body fitness',
        author: 'Robert Harry',
        date: '25 Feb 2021',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt of the graduate ut labore et dolore magna aliqua.',
        likes: 80,
        isVideo: true,
    },
];

const BlogLeft = () => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <section className="wpo-blog-pg-section left section-padding">
            <div className="container">
                <div className="row">
                    <BlogSidebar />
                    <div className="col col-lg-8">
                        <div className="wpo-blog-content">
                            {blogs.map((blog) => (
                                <div className={`post format-${blog.format}`} key={blog.id}>
                                    <div className="entry-media">
                                        <img src={blog.image} alt={blog.title} />
                                        {blog.isVideo && (
                                            <div className="video-btn2">
                                                <VideoModal />
                                            </div>
                                        )}
                                    </div>
                                    <ul className="entry-meta">
                                        <li>
                                            <Link onClick={ClickHandler} to="/blog-details">
                                                By {blog.author}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/blog-details">
                                                {blog.date}
                                            </Link>
                                        </li>
                                    </ul>
                                    <h3>
                                        <Link onClick={ClickHandler} to="/blog-details">
                                            {blog.title}
                                        </Link>
                                    </h3>
                                    <p>{blog.description}</p>
                                    <div className="entry-bottom">
                                        <Link onClick={ClickHandler} to="/blog-details" className="read-more">
                                            View More...
                                        </Link>
                                        <ul>
                                            <li>
                                                <i className="fi flaticon-like"></i>
                                                {blog.likes}
                                            </li>
                                            <li>
                                                <i className="fi flaticon-share"></i>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="blog-thumb-badge">
                                        <div className="blog-thumb-text">
                                            <span>{blog.category}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="pagination-wrapper pagination-wrapper-left">
                                <ul className="pg-pagination">
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details" aria-label="Previous">
                                            <i className="fi ti-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="active">
                                        <Link onClick={ClickHandler} to="/blog-details">
                                            1
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details">
                                            2
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details">
                                            3
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={ClickHandler} to="/blog-details" aria-label="Next">
                                            <i className="fi ti-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogLeft;

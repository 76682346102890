import { EditorState, convertFromRaw, convertToRaw , convertFromHTML, ContentState } from "draft-js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AiOutlineClose } from "react-icons/ai";
import { bannerApi } from "../../../redux/apis/bannerApi";
import { toast } from "react-toastify";


const BannerSetting = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const id = "675131ea5ba69e9df67960fd";
  const { data: bannerData } = bannerApi.useGetBannerByIdQuery(id);
  const banner = bannerData?.data || {};
  const [updatebanner] = bannerApi.useUpdateBannerMutation();
  const [updatebannerWithImage] = bannerApi.useUpdateBannerWithImageMutation();
  const [imageFile, setImageFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(banner?.image || null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (banner) {
      setValue("title", banner?.title || "");
      setValue("youtubeVideoUrl", banner?.youtubeVideoUrl || "");
      setSelectedImage(banner?.image || null);
  
      if (banner?.description) {
        try {
          // First try to parse as JSON
          const contentState = convertFromRaw(JSON.parse(banner.description));
          setEditorState(EditorState.createWithContent(contentState));
        } catch (error) {
          console.error("Invalid JSON, falling back to plain text:", error);
  
          // If not JSON, treat it as plain text and convert it
          const blocksFromHTML = convertFromHTML(banner.description);
          const contentState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          setEditorState(EditorState.createWithContent(contentState));
        }
      } else {
        // If no description, set an empty editor state
        setEditorState(EditorState.createEmpty());
      }
    }
  }, [banner, setValue]);
  
  

  const onEditorStateChange = (newState) => {
    setEditorState(newState);
  };

  const handleImageChange = (files) => {
    if (files && files.length > 0) {
      const imageFile = files[0];
      setImageFile(imageFile);

      const imageUrl = URL.createObjectURL(imageFile);
      setSelectedImage(imageUrl);
    }
  };

  const handleImageDelete = () => {
    setSelectedImage(null);
    setImageFile(null);
    document.querySelector("#image").value = null;
  };

// Update function
const handleUpdateBanner = async (data) => {
    try {
      // Convert editor state to a raw JSON string
      const rawContent =  convertToRaw(editorState.getCurrentContent())
      const description = JSON.stringify(rawContent);
  
      // Update data with description
      data.description = description;
  
      // Function to handle API response
      const handleResponse = (res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message);
        } else {
          throw new Error(res?.data?.message || "Failed to update banner.");
        }
      };
  
      if (!imageFile) {
        // Prepare data for updating without image
        const updateData = {
          title: data?.title,
          youtubeVideoUrl: data?.youtubeVideoUrl,
          description,
        };
  
        // API call for updating without image
        const res = await updatebanner({ id, data: updateData });
        handleResponse(res);
      } else {
        // Prepare FormData for updating with image
        const formData = new FormData();
        formData.append("topic", data?.topic);
        formData.append("title", data?.title);
        formData.append("description", description);
        formData.append("image", imageFile);
  
        // API call for updating with image
        const res = await updatebannerWithImage({ id, formData });
        handleResponse(res);
      }
    } catch (error) {
      // Show error to the user
      toast.error(error?.message || "An error occurred while updating.");
    }
  };
  
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form
        onSubmit={handleSubmit(handleUpdateBanner)}
        className="w-full container bg-white shadow-md rounded-lg p-6"
      >
        <h2 className="text-2xl font-bold mb-6 text-gray-700 text-center">
          Banner Settings
        </h2>

        <div className="grid grid-cols-3 gap-2">
          <div className="col-span-2">
            {/* Title */}
            <div className="mb-4">
              <label htmlFor="title" className="block text-gray-700 font-medium">
                Topic <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="topic"
                {...register("topic", { required: "topic is required" })}
                className={`w-full mt-2 px-4 py-2 border ${
                  errors.topic ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                placeholder="Enter topic"
              />
              {errors.topic && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.topic.message}
                </p>
              )}
            </div>
            {/* Title */}
            <div className="mb-4">
              <label htmlFor="title" className="block text-gray-700 font-medium">
                Title <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="title"
                {...register("title", { required: "Title is required" })}
                className={`w-full mt-2 px-4 py-2 border ${
                  errors.title ? "border-red-500" : "border-gray-300"
                } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
                placeholder="Enter title"
              />
              {errors.title && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.title.message}
                </p>
              )}
            </div>

  
            {/* Description */}
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description <span className="text-red-500">*</span>
              </label>
              <Editor
                editorState={editorState}
                toolbarClassName="border border-gray-300 rounded-t-md p-2"
                wrapperClassName="border border-gray-300 rounded-md"
                editorClassName="p-3 min-h-[200px] rounded-b-md focus:outline-none"
                onEditorStateChange={onEditorStateChange}
              />
            </div>
          </div>

          {/* Image */}
          <div className="mt-4 col-span-1">
            <label
              htmlFor="image"
              className="block text-sm font-medium text-gray-700"
            >
              Banner Image <span className="text-red-500">*</span>
            </label>

            <div className="cursor-pointer mt-3 p-3 border-dashed border-2 border-gray-300 bg-gray-50 rounded-md flex items-center justify-center relative">
              {selectedImage ? (
                <>
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="w-full h-[290px] object-cover rounded-md"
                  />
                  <button
                    type="button"
                    onClick={handleImageDelete}
                    className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full focus:outline-none hover:bg-red-600"
                  >
                    <AiOutlineClose className="text-lg" />
                  </button>
                </>
              ) : (
                <span
                  onClick={() => document.querySelector("#image").click()}
                  className="flex flex-col items-center h-[290px]"
                >
                  <span className="flex flex-col items-center my-auto">
                    <span className="text-3xl text-gray-400">📁</span>
                    <p className="mt-2 text-sm text-gray-500 p-2 border rounded-md">
                      Select Image
                    </p>
                  </span>
                </span>
              )}
            </div>

            <input
              type="file"
              id="image"
              accept="image/*"
              className="hidden"
              onChange={(e) => handleImageChange(e.target.files)}
            />
            {errors.image && (
              <span className="text-red-500">This field is required</span>
            )}
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6 flex justify-center">
          <button
            type="submit"
            className="w-[200px] bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default BannerSetting;

import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle'
import BlogDetailsComponent from '../../components/BlogDetails/BlogDetails';



const BlogDetails =() => {
    return(
        <Fragment>
            
            <PageTitle pageTitle={'Latest News'} pagesub={'Blog'}/> 
            <BlogDetailsComponent />
           
        </Fragment>
    )
};
export default BlogDetails;

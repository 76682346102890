import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle'
import About from '../../components/about'
import EventSection from '../../components/event'
import abimg from '../../images/about3.png'
import evn1 from '../../images/event/img-3.jpg'
import evn2 from '../../images/event/img-4.jpg'


const AboutPage =() => {
    return(
        <Fragment>
            
            <PageTitle pageTitle={'About Us'} pagesub={'About'}/> 
            <About aboutImg={abimg}/>
            <EventSection eventImg1={evn1} eventImg2={evn2}  EventClass={'wpo-event-area-2'}/>
            
           
        </Fragment>
    )
};
export default AboutPage;

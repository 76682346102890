import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle'
import EresourceComponent from '../../components/EresourceComponent';
import { useParams } from 'react-router-dom';
import { eResourceApi } from '../../redux/apis/eresourceApi';



const ErecoursePage =() => {
    const { slug, id } = useParams();
    const { data: eresourceData, isLoading } = eResourceApi.useGetEresourcesByCategoryQuery(id);

    if(isLoading){
        return <p>Loading..</p>
    }

    const eresources = eresourceData?.data || [];

    return(
        <Fragment>
            
            <PageTitle pageTitle={'Eresource'} pagesub={slug}/> 
            <EresourceComponent eresources={eresources}/>
        </Fragment>
    )
};
export default ErecoursePage;

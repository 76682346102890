import React from 'react';
import Navbar from '../components/Navbar';
import { Outlet } from 'react-router-dom';
import Footer from '../components/footer';
import Scrollbar from '../components/scrollbar';

const MainLayout = () => {
    return (
        <div>
            <Navbar />
             <Outlet />
             <Footer />
            <Scrollbar />
        </div>
    );
};

export default MainLayout;
import apiService from "../api-service/api-service";

export const eResourceApi = apiService.injectEndpoints({
    endpoints: (builder) => ({
        // Create a new e-resource
        createEresource: builder.mutation({
            query: (eResource) => ({
                url: "eresources/create-eresource?locationFolder=pdf-files",
                method: "POST",
                body: eResource
            }),
        }),

        // Get all e-resources
        getAllEresources: builder.query({
            query: () => ({
                url: "eresources/all-eresources"
            })
        }),

        // Get e-resources by category
        getEresourcesByCategory: builder.query({
            query: (categoryId) => ({
                url: `eresources/category/${categoryId}`
            })
        }),

        // Get e-resources by user
        getEresourcesByUser: builder.query({
            query: (userId) => ({
                url: `eresources/user/${userId}`
            })
        }),

        // Get a specific e-resource by id
        getEresourceById: builder.query({
            query: (id) => ({
                url: `eresources/${id}`
            })
        }),

        // Update an e-resource
        updateEresource: builder.mutation({
            query: ({ id, formData }) => ({
                url: `eresources/update/${id}`,
                method: "PATCH",
                body: formData
            })
        }),

        // Delete an e-resource
        deleteEresource: builder.mutation({
            query: (id) => ({
                url: `eresources/delete/${id}`,
                method: "DELETE"
            })
        }),
    }),
});

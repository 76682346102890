import React from "react";
import { eventsApi } from "../../../redux/apis/eventsApi";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FaPlus } from "react-icons/fa";

const AllEvents = () => {

  const { data: events, isLoading, refetch } = eventsApi.useGetAlleventsQuery();
  const [ deleteEvent ] = eventsApi.useDeleteEventMutation();

  const allevents = events?.data?.events || [];

if(isLoading){
  return <p>Loading..</p>
}

  //  handle delte a blog
  const handleDeleteEvent = async (id, eventTtile) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert ${eventTtile}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEvent(id).then((res) => {
          if (res?.data?.success)
            refetch();
            Swal.fire({
              title: "Deleted!",
              text: `${eventTtile} has been deleted `,
              icon: "success",
            });
        });
      }
    });
  };

  return (
    <div className="container mx-auto p-6  shadow-md rounded-md mt-10  w-full  bg-white ">
      
      <div className=" flex justify-between items-center">
       <h2 className="text-2xl font-semibold text-gray-800 mb-6">All Events</h2>
         <Link to={"/dashboard/add-event"} className=" flex gap-2 items-center text-white bg-blue-600 hover:bg-blue-800 px-2 py-1 rounded-md"> <FaPlus /> Add New </Link>
       </div>

        <table className="w-full border-collapse border border-gray-300 text-left text-sm text-gray-700">
          <thead>
            <tr className="bg-gray-100 text-gray-700">
              <th className="border border-gray-300 px-4 py-2">#</th>
              <th className="border border-gray-300 px-4 py-2">Image</th>
              <th className="border border-gray-300 px-4 py-2">Title</th>
              <th className="border border-gray-300 px-4 py-2">Location</th>
              <th className="border border-gray-300 px-4 py-2">Start time</th>
              <th className="border border-gray-300 px-4 py-2">End time</th>
              <th className="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {allevents.map((event, index) => (
              <tr key={event.id} className="hover:bg-gray-50 text-sm">
                <td className="border border-gray-300 px-2 py-1">{index + 1}</td>
                <td className="border border-gray-300 px-2 py-1">
                  <img src={event?.image} alt="" className=" h-16 w-18"/>
                </td>
                <td className="border border-gray-300 px-2 py-1 hover:text-blue-600"><Link to={`/${event?.category}/${event?._id}`}>{event.title}</Link>  </td>
                <td className="border border-gray-300 px-2 py-1">{event.location}</td>
             
                <td className="border border-gray-300 px-2 py-1">
                  {new Date(event.startDate).toLocaleString()}
                </td>
                <td className="border border-gray-300 px-2 py-1">
                  {new Date(event.endDate).toLocaleString()}
                </td>
                <td className="border border-gray-300 px-2 py-1 ">
            
                 <span className="flex gap-2">
                 <Link to={`/dashboard/update-event/${event?._id}`}
                    className="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-700"
                  >
                    Edit
                  </Link>
                  <button
                    className="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-700"
                    onClick={() => handleDeleteEvent(event?._id, event?.title)}
                  >
                    Delete
                  </button>
                 </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
    </div>
  );
};

export default AllEvents;

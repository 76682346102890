import React from 'react';
import useAuth from '../../../hooks/auth/useAuth';
import { FcGoogle } from 'react-icons/fc';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { usersApi } from '../../../redux/apis/usersApi';

const GoogleLogin = () => {
    const {  googleSignIn }  = useAuth()
    // const [ createUsers] = usersApi.useCreateUsersMutation();
    const [createUsers] = usersApi.useCreateUsersMutation()
    const navigate = useNavigate();

           // handle google sign in function
           const handleGooogleSignIn = async () => {
            try {
              const result = await googleSignIn();
      
              if(result?.user){
                const userData = {
                  name: result.user?.displayName,
                  email: result.user?.email,
                  profilePicture: result.user?.photoURL,
                };
                await createUsers(userData).unwrap().then((res) => {
                if(res)
                    toast.error(res?.data?.message)
                    navigate("/");
                });
              }
        
            } catch (error) {
              console.error("Error during Google sign-in:", error);
              toast.error(error?.data?.message)
            }
          };

    return (

        <div className="flex  items-center justify-center mt-4">
         {/* Google Login Button */}
           <button
             onClick={handleGooogleSignIn}
             className="flex items-center gap-2 bg-white dark:bg-gray-700 dark:hover:bg-gray-600 border border-gray-300 py-2 px-4 rounded-md hover:bg-gray-100 w-full  justify-center"
           >
             <FcGoogle className=" text-2xl" />
             <span>Continue with Google</span>
           </button>
        </div>
    );
};

export default GoogleLogin;
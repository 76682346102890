import React, {Fragment} from 'react';
import PageTitle from '../../components/pagetitle'
import BlogFullwidth from '../../components/BlogFullwidth'



const BlogPageFullwidth =() => {
    return(
        <Fragment>
            
            <PageTitle pageTitle={'Latest News'} pagesub={'Blog'}/> 
            <BlogFullwidth/>
            
        </Fragment>
    )
};
export default BlogPageFullwidth;


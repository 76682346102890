import React from 'react';
import {Link} from 'react-router-dom'
import ins2 from '../../images/recent-posts/img-2.jpg'
import ins3 from '../../images/recent-posts/img-3.jpg'
import evnt from '../../images/blog/profile.png'
import './style.css'
import { blogsApi } from '../../redux/apis/blogsApi';
import { FaFacebookF, FaGoogle, FaInstagram, FaTwitter } from 'react-icons/fa';

const BlogSidebar = ({author}) => {
    const { data: categoryData, isLoading } = blogsApi.useGetAllBlogsCategoriesQuery();
    const { data: blogsData, isLoading: blogsLoading } = blogsApi.useGetAllBlogsQuery();


    if(isLoading || blogsLoading){
        return <p>Loading..</p>
    }

    const categories = categoryData?.data || [];
    const allblogs = blogsData?.data?.blogs || [];
    const blogs = allblogs?.length >= 3 ? allblogs.slice(0,3) : allblogs || [];
    

    const SubmitHandler = (e) =>{
       e.preventDefault()
    };





    return(
        <div className="col col-lg-4 col-md-12 col-12">
            <div className="wpo-blog-sidebar">
                <div className="widget profile-widget">
                    <div className="profile-img text-center">
                        <div className=' flex justify-center'>
                        <img src={author?.profilePicture} alt="" className=' w-44 h-44' />

                        </div>
                        <h2>{author?.name}</h2>
                        <p>Hi! here is our blog. Read our post - and be with us</p>
                    </div>
                    <div className="pro-social">
                        <ul>
                        <li className='  bg-blue-500 hover:bg-blue-800 p-1 rounded-full'>
                           <Link to="/blog-details">
                             <FaFacebookF className=' text-white' />
                           </Link>
                         </li>
                         <li className='  bg-blue-500 hover:bg-blue-800 p-1 rounded-full'>
                           <Link to="/blog-details">
                             <FaTwitter className=' text-white' />
                           </Link>
                         </li>
                         <li className='  bg-blue-500 hover:bg-blue-800 p-1 rounded-full'>
                           <Link to="/blog-details">
                             <FaInstagram className=' text-white' />
                           </Link>
                         </li>
                         <li className='  bg-blue-500 hover:bg-blue-800 p-1 rounded-full'>
                           <Link to="/blog-details">
                             <FaGoogle className=' text-white' />
                           </Link>
                         </li>
                        </ul>
                    </div>
                </div>
                <div className="widget search-widget">
                    <form onSubmit={SubmitHandler}>
                        <div>
                            <input type="text" className="form-control" placeholder="Search Post.."/>
                            <button type="submit"><i className="ti-search"></i></button>
                        </div>
                    </form>
                </div>
                <div className="widget category-widget">
                    <h3>Categories</h3>
                    <ul>
                        {
                            categories?.length > 0 && categories?.map((category) =>   <li key={category?._id}><Link to="/blog-details">{category?.categoryName}<span>({category?.totalBlogs})</span></Link></li> )
                        }
                  
                    </ul>
                </div>
                <div className="widget recent-post-widget">
                    <h3>Recent posts</h3>
                    <div className="posts">
                    {
                       blogs?.length > 0 &&
                       blogs?.map((blog) => {
                           const formattedDate = new Date(blog?.createdAt).toLocaleDateString('en-GB', {
                               day: '2-digit',
                               month: 'short',
                               year: 'numeric',
                           });
                   
                           return (
                               <div key={blog?._id} className="post">
                                   <div className="img-holder">
                                       <img src={blog?.image} alt="" />
                                   </div>
                                   <div className="details">
                                       <h4>
                                           <Link to="/blog-details">{blog?.title}</Link>
                                       </h4>
                                       <span className="date">{formattedDate}</span>
                                   </div>
                               </div>
                           );
                       })
                    }


                
                    </div>
                </div>
                <div className="widget tag-widget">
                    <h3>Tags</h3>
                    <ul>
                        <li><Link to="/blog-details">Salat</Link></li>
                        <li><Link to="/blog-details">Quran</Link></li>
                        <li><Link to="/blog-details">Books</Link></li>
                        <li><Link to="/blog-details">Education</Link></li>
                        <li><Link to="/blog-details">World Wide</Link></li>
                        <li><Link to="/blog-details">Marriage</Link></li>
                        <li><Link to="/blog-details">Hajj</Link></li>
                        <li><Link to="/blog-details">Health</Link></li>
                    </ul>
                </div>
            </div>
        </div>
     )
        
}

export default BlogSidebar;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { headerApi } from "../../../redux/apis/headerApi";
import { toast } from "react-toastify";
import { pagesApi } from "../../../redux/apis/pagesApi";

const HeaderSettings = () => {
  const { register, handleSubmit, watch } = useForm();
  const id = "674c3b5e65e16a01d9861216";
  const { data: headerData, isLoading, refetch } = headerApi.useGetHeaderByIdQuery(id);
  const [ updateHeader ] = headerApi.useUpdateHeaderMutation();
  const [ updateHeaderWithLogo ] = headerApi.useUpdateHeaderWithLogoMutation();
  const [ createPages ] = pagesApi.useCreatePagesMutation();

  const headerInfo = headerData?.data;
  const [navMenus, setNavMenus] = useState([]);

  // State to hold logo preview
  const [logoPreview, setLogoPreview] = useState(headerInfo?.headerLogo || "");

  // Watch for changes to the file input
  const watchLogo = watch("headerLogo");

  useEffect(() => {
    setLogoPreview(headerInfo?.headerLogo)
    if (watchLogo && watchLogo[0]) {
      const file = watchLogo[0];
      const previewUrl = URL.createObjectURL(file);
      setLogoPreview(previewUrl);
    }
    if(headerData){
        const headerMenus = headerInfo?.headerMenus || [];
        setNavMenus(headerMenus)
    }
  }, [watchLogo, headerData]);


  const handleAddMenu = () => {
    setNavMenus((prevMenus) => {
      const updatedMenus = [...prevMenus, { menuTitle: "", menuUrl: "" }];
      return updatedMenus;
    });
  };
  
  const handleRemoveMenu = (index) => {
    setNavMenus((prevMenus) => {
      const updatedMenus = prevMenus.filter((_, i) => i !== index);
      return updatedMenus;
    });
  };

  console.log("navMenus: ", navMenus)

//   handel update header info function
const handleUpdateHeader = async (data) => {
    try {
      // Create a FormData object to send data, including the file (if uploaded)
      
      const pages = navMenus?.map((menu) => {
        return { title: menu.menuTitle, link: menu.menuUrl }; // Properly return the object
      });     
      
     const res = await createPages(pages);

      console.log(res)

      // Add header logo if a new one is uploaded
      if (data?.headerLogo && data.headerLogo[0]) {
        const formData = new FormData();
        formData.append("helpLineNumber", data?.helpLineNumber);  
        formData.append("headerLogo", data.headerLogo[0]);
            // Add navigation menus
        formData.append("headerMenus", JSON.stringify(navMenus));
        // Make the API call to update the header information
        const response = await updateHeaderWithLogo({ id, formData });
  
        if (response?.data?.success) {
          refetch();
          toast.success(response?.data?.message);
        } else {
          toast.error("Failed to update header. Please try again.");
        }
      } else {
        const updatedData = {
          helpLineNumber: data?.helpLineNumber,
          headerMenus: JSON.stringify(navMenus),
          email: data?.email
        };

        const res = await updateHeader({ id, formData: updatedData})
        if (res?.data?.success) {
          refetch();
          toast.success(res?.data?.message);
        } else {
          toast.error("Failed to update header. Please try again.");
        }
      }
  
  

    } catch (error) {
      console.error("Error updating header:", error);
      toast.error("An error occurred while updating the header.");
    }
  };
  
   // Check loading and show a spinner
 if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Header Settings</h2>
      <form onSubmit={handleSubmit(handleUpdateHeader)}>
        {/* Header Logo */}
        <div className="mb-4">
          <label className="font-medium block mb-2" htmlFor="headerLogo">
            Header Logo
          </label>
          <div className="flex items-center space-x-4">
            <input
              type="file"
              id="headerLogo"
              accept="image/*"
              {...register("headerLogo")}
              className="w-2/3 px-3 py-2 border rounded-lg"
            />
            {logoPreview && (
              <div className="w-24 h-24">
                <img
                  src={logoPreview}
                  alt="Logo Preview"
                  className="w-full h-full object-contain border rounded-lg"
                />
              </div>
            )}
          </div>
        </div>

        {/* Help Line */}
        <div className="mb-4 flex items-center space-x-4">
          <label className="w-1/3 font-medium" htmlFor="helpLineNumber">
            Help line number
          </label>
          <input
            type="text"
            id="helpLineNumber"
            {...register("helpLineNumber")}
            className="w-2/3 px-3 py-2 border rounded-lg"
            placeholder="+88 01111093345"
            defaultValue={headerInfo?.helpLineNumber}
          />
        </div>

        {/* Help Line email */}
        <div className="mb-4 flex items-center space-x-4">
          <label className="w-1/3 font-medium" htmlFor="email">
            Support email
          </label>
          <input
            type="text"
            id="email"
            {...register("email")}
            className="w-2/3 px-3 py-2 border rounded-lg"
            placeholder="email"
            defaultValue={headerInfo?.email}
          />
        </div>
        
        {/* Header Nav Menu */}
        <div className="mb-4">
          <label className="block font-medium mb-2">Header Nav Menu</label>
          <div className="space-y-2">
            {navMenus.map((menu, index) => (
              <div key={index} className="flex items-center space-x-4">
                <input
                  type="text"
                  value={menu.menuTitle}
                  onChange={(e) => {
                    const updatedMenus = navMenus.map((menu, i) =>
                      i === index ? { ...menu, menuTitle: e.target.value } : menu
                    );
                    setNavMenus(updatedMenus);
                  }}
                  placeholder="Menu Title"
                  className="block w-1/3 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  value={menu.menuUrl}
                  onChange={(e) => {
                    const updatedMenus = navMenus.map((menu, i) =>
                      i === index ? { ...menu, menuUrl: e.target.value } : menu
                    );
                    setNavMenus(updatedMenus);
                  }}
                  placeholder="Menu URL"
                  className="block w-2/3 px-3 py-2 border rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveMenu(index)}
                  className="px-3 py-1 text-red-600 border rounded-lg hover:bg-gray-100"
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
          <button
            type="button"
            onClick={handleAddMenu}
            className="mt-3 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Add New
          </button>
        </div>


        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="w-[200px] px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-800"
          >
            Save changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default HeaderSettings;

import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import './style.scss';
import useAuth from "../../hooks/auth/useAuth";
import { usersApi } from "../../redux/apis/usersApi";
import GoogleLogin from "../../dashboard/components/shared/GoogleLogin";

const SignUpPage = () => {
    const navigate = useNavigate();
    const { createUser } = useAuth();
    const [createUsers] = usersApi.useCreateUsersMutation();

    // Using react-hook-form
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
    } = useForm();

    const onSubmit = async (data) => {
        
        try{
            const res = await createUser(data?.email, data?.password);
            
            if(res?.user) {
                const userData = {
                    name: data?.name,
                    email: data?.email,
                    password: data?.password
                }
                 await createUsers(userData).unwrap(); // API call to /users/create-user
                toast.success("Registration complete successfully!");
                reset(); // Reset form values
                navigate("/");
            }
        } catch (error){
            console.log(error)
        }

    };

    const password = watch("password");



    return (
        <Grid className="loginWrapper">
            <Grid className="loginForm">
                <h2>Signup</h2>
                <p>Signup your account</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={3}>
                        {/* Full Name */}
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Full Name"
                                variant="outlined"
                                label="Name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...register("name", {
                                    required: "Full name is required",
                                    pattern: {
                                        value: /^[A-Za-z\s]+$/,
                                        message: "Only alphabetic characters are allowed",
                                    },
                                })}
                                error={!!errors.full_name}
                                helperText={errors.full_name?.message}
                            />
                        </Grid>

                        {/* Email */}
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="E-mail"
                                variant="outlined"
                                label="E-mail"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...register("email", {
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: "Invalid email address",
                                    },
                                })}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                        </Grid>

                        {/* Password */}
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Password"
                                variant="outlined"
                                label="Password"
                                type="password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...register("password", {
                                    required: "Password is required",
                                    minLength: {
                                        value: 6,
                                        message: "Password must be at least 6 characters",
                                    },
                                })}
                                error={!!errors.password}
                                helperText={errors.password?.message}
                            />
                        </Grid>

                        {/* Confirm Password */}
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Confirm Password"
                                variant="outlined"
                                label="Confirm Password"
                                type="password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                {...register("confirm_password", {
                                    required: "Confirm password is required",
                                    validate: (value) =>
                                        value === password || "Passwords do not match",
                                })}
                                error={!!errors.confirm_password}
                                helperText={errors.confirm_password?.message}
                            />
                        </Grid>

                        {/* Submit Button */}
                        <Grid item xs={12}>
                            <Grid className="formFooter">
                                <Button
                                    fullWidth
                                    className="cBtn cBtnLarge cBtnTheme"
                                    type="submit"
                                >
                                    Sign Up
                                </Button>
                            </Grid>
                      


                        </Grid>
                    </Grid>
                </form>

              <div>
                        {/* Google Login Button */}
                       <GoogleLogin />
                            <p className="noteHelp">
                                Already have an account?{" "}
                                <Link to="/login">Return to Sign In</Link>
                            </p>
              </div>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    );
};

export default SignUpPage;

import React, { useState } from 'react';
import './style.css';
import { eResourceApi } from '../../redux/apis/eresourceApi';

const EresourceComponent = ({eresources}) => {
    const [currentIndex, setCurrentIndex] = useState(0); // State to track the current PDF index



    const handleNext = () => {
        if (currentIndex < eresources?.length - 1) {
            setCurrentIndex(currentIndex + 1);
            window.scrollTo(10, 0); // Scroll to top
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            window.scrollTo(10, 0); // Scroll to top
        }
    };

    if (eresources?.length === 0) {
        return <p>No eResources available</p>;
    }

    return (
        <section className="service-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div>
                        <h2 className=' text-center my-4 text-3xl'>{eresources[currentIndex]?.title}</h2>
                        <iframe
                            src={eresources[currentIndex]?.pdfFile}
                            width="100%"
                            className="h-screen"
                            title={`PDF ${currentIndex}`}
                        ></iframe>
                        <div className="prv-nx flex justify-center">
                            <button
                                className="pre-btn"
                                onClick={handlePrevious}
                                disabled={currentIndex === 0}
                            >
                                Previous
                            </button>
                            <button
                                className="nex-btn"
                                onClick={handleNext}
                                disabled={currentIndex === eresources.length - 1}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EresourceComponent;



import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { footerApi } from "../../../redux/apis/footerApi";
import { toast } from "react-toastify";

const FooterSetting = () => {
  const { register, handleSubmit, watch } = useForm();
  const id = "674d5940535c1c601142ff90";
  const { data: footerData, error, isLoading } = footerApi.useGetFooterByIdQuery(id);
  const [updateFooter] = footerApi.useUpdateFooterMutation();
  const [updateFooterWithLogo] = footerApi.useUpdateFooterWithLogoMutation();
  const [servicesMmanu, setServicesMmanu] = useState([]);
  const [usefulLinksManu, setUsefulLinksManu] = useState([]);


  const footerInfo = footerData?.data;
  const { aboutSection, servicesSection, usefulLinks, contactSection } = footerInfo || {};
  const { socialLinks } = aboutSection || {};

  // State to hold logo preview
  const [logoPreview, setLogoPreview] = useState(aboutSection?.footerLogo || "");

  // Watch for changes to the file input
  const watchLogo = watch("footerLogo");

  useEffect(() => {
    setLogoPreview(footerInfo?.footerLogo)
    if (watchLogo && watchLogo[0]) {
      const file = watchLogo[0];
      const previewUrl = URL.createObjectURL(file);
      setLogoPreview(previewUrl);
    }
    if(footerData){
      setUsefulLinksManu(usefulLinks?.manus);
      setServicesMmanu(servicesSection?.manus);
    }
  }, [watchLogo, footerData]);

  const handleAddMenu = () => {
    setServicesMmanu((prevMenus) => {
      const updatedMenus = [...prevMenus, { menuTitle: "", menuUrl: "" }];
      return updatedMenus;
    });
  };

  const handleRemoveMenu = (index) => {
    setServicesMmanu((prevMenus) => {
      const updatedMenus = prevMenus.filter((_, i) => i !== index);
      return updatedMenus;
    });
  };

  // Handle update footer info function
  const handleUpdateFooter = async (data) => {
    try {
      // Create a FormData object to send data, including the file (if uploaded)
      const formData = new FormData();

      // Add help line number
      formData.append("helpLineNumber", data?.helpLineNumber);

      // Add footer logo if a new one is uploaded
      if (data?.footerLogo && data.footerLogo[0]) {
        formData.append("footerLogo", data.footerLogo[0]);
      }

      // Add navigation menus
      formData.append("headerMenus", JSON.stringify(servicesMmanu));

      // Make the API call to update the footer information
      const response = await updateFooter({ id, formData });

      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error("Failed to update footer. Please try again.");
      }
    } catch (error) {
      console.error("Error updating footer:", error);
      toast.error("An error occurred while updating the footer.");
    }
  };

  // Check loading and show a spinner
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Footer Settings</h2>
      <form onSubmit={handleSubmit(handleUpdateFooter)}>

        {/* Left section: about section logo about us & social links */}
        <div className="mb-5">
          <h2 className="my-2 text-xl">About Section</h2>

          {/* Footer logo */}
          <div className="mb-4">
            <label className="font-medium block mb-2" htmlFor="footerLogo">
              Footer Logo
            </label>
            <div className="flex items-center space-x-4">
              <input
                type="file"
                id="footerLogo"
                accept="image/*"
                {...register("footerLogo")}
                className="w-2/3 px-3 py-2 border rounded-lg"
              />
              {logoPreview && (
                <div className="w-24 h-24">
                  <img
                    src={logoPreview}
                    alt="Logo Preview"
                    className="w-full h-full object-contain border rounded-lg"
                  />
                </div>
              )}
            </div>
          </div>

          {/* About us */}
          <div className="mb-4">
            <label className="font-medium block mb-2" htmlFor="footerLogo">
              About us
            </label>
            <textarea
              type="text"
              {...register("aboutUs")}
              className="w-2/3 px-3 py-2 border rounded-lg"
              defaultValue={aboutSection?.aboutUs}
            />
          </div>

          {/* Social links */}
          <div className="mb-4">
            <label className="font-medium block mb-2" htmlFor="footerLogo">
              Social media links
            </label>
            <div>
              <div className="my-2">
                <input
                  type="text"
                  {...register("facebook")}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="Enter Facebook link"
                  defaultValue={socialLinks?.facebook}
                />
              </div>
              <div className="my-2">
                <input
                  type="text"
                  {...register("twitter")}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="Enter Twitter link"
                  defaultValue={socialLinks?.twitter}
                />
              </div>
              <div className="my-2">
                <input
                  type="text"
                  {...register("instagram")}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="Enter Instagram link"
                  defaultValue={socialLinks?.instagram}
                />
              </div>
              <div className="my-2">
                <input
                  type="text"
                  {...register("youtube")}
                  className="w-full px-3 py-2 border rounded-lg"
                  placeholder="Enter YouTube link"
                  defaultValue={socialLinks?.youtube}
                />
              </div>
            </div>
          </div>
        </div>

 

        {/* Services Menu */}
        <div className="mb-4">
          <label className="block font-medium mb-2">Services Menu</label>
          <div className="space-y-2">
            {servicesMmanu?.length > 0 && servicesMmanu?.map((menu, index) => (
              <div key={index} className="flex items-center space-x-4">
                <input
                  type="text"
                  defaultValue={menu.name}
                  onChange={(e) => {
                    const updatedMenus = servicesMmanu.map((menu, i) =>
                      i === index ? { name: e.target.value } : menu
                    );
                    setServicesMmanu(updatedMenus);
                  }}
                  placeholder="Menu Title"
                  {...register(`navMenu[${index}].${menu?.name}`)}
                  className="block w-1/3 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  defaultValue={menu.link}
                  onChange={(e) => {
                    const updatedMenus = servicesMmanu.map((menu, i) =>
                      i === index ? { ...menu, link: e.target.value } : menu
                    );
                    setServicesMmanu(updatedMenus);
                  }}
                  placeholder="Menu URL"
                  {...register(`navMenu[${index}].${menu?.link}`)}
                  className="block w-2/3 px-3 py-2 border rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveMenu(index)}
                  className="px-3 py-1 text-red-600 border rounded-lg hover:bg-gray-100"
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
          <button
            type="button"
            onClick={handleAddMenu}
            className="mt-3 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Add New
          </button>
        </div>

        {/* useful links */}
        <div className="mb-4">
          <label className="block font-medium mb-2">Useful links</label>
          <div className="space-y-2">
            {usefulLinksManu?.length > 0 && usefulLinksManu?.map((menu, index) => (
              <div key={index} className="flex items-center space-x-4">
                <input
                  type="text"
                  defaultValue={menu.name}
                  onChange={(e) => {
                    const updatedMenus = servicesMmanu.map((menu, i) =>
                      i === index ? { name: e.target.value } : menu
                    );
                    setServicesMmanu(updatedMenus);
                  }}
                  placeholder="Menu Title"
                  {...register(`navMenu[${index}].${menu?.name}`)}
                  className="block w-1/3 px-3 py-2 border rounded-lg"
                />
                <input
                  type="text"
                  defaultValue={menu.link}
                  onChange={(e) => {
                    const updatedMenus = servicesMmanu.map((menu, i) =>
                      i === index ? { ...menu, link: e.target.value } : menu
                    );
                    setServicesMmanu(updatedMenus);
                  }}
                  placeholder="Menu URL"
                  {...register(`navMenu[${index}].${menu?.link}`)}
                  className="block w-2/3 px-3 py-2 border rounded-lg"
                />
                <button
                  type="button"
                  onClick={() => handleRemoveMenu(index)}
                  className="px-3 py-1 text-red-600 border rounded-lg hover:bg-gray-100"
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
          <button
            type="button"
            onClick={handleAddMenu}
            className="mt-3 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Add New
          </button>
        </div>

        {/* Contact Section */}
        <div className="mb-5">
          <h2 className="my-2 text-xl">Contact Section</h2>

          {/* Address */}
          <div className="my-2">
            <label className="font-medium block mb-2" htmlFor="footerLogo">
              Address
            </label>
            <input
              type="text"
              {...register("address")}
              className="w-full px-3 py-2 border rounded-lg"
              placeholder="Enter Address"
              defaultValue={contactSection?.address}
            />
          </div>

                     {/* Phone number  */}
        <div className="mb-4 flex items-center space-x-4">
           <label className="font-medium block mb-2 w-[40%] md:w-1/4">
             Contact number
           </label>
           <div className="space-x-4  w-[60%] md:w-[75%]">
             <input            type="text"
              {...register("contactNumber")}
              className="w-full px-3 py-2 border rounded-lg"
              placeholder="Type your company contact number"
              defaultValue={contactSection?.contactNumber}

            />
          </div>
        </div>

          {/* email  */}
        <div className="mb-4 flex items-center space-x-4">
          <label className="font-medium block mb-2 w-[40%] md:w-1/4" >
            Email
          </label>
          <div className="space-x-4  w-[60%] md:w-[75%]">
            <input
              type="text"
              {...register("email")}
              className="w-full px-3 py-2 border rounded-lg"
              placeholder="Type your company email"
              defaultValue={contactSection?.email}

            />
          </div>
        </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default FooterSetting;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { categoryApi } from "../../../redux/apis/categoryApi";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

const AddCategory = () => {
  const { register, handleSubmit, formState: { errors }, reset} = useForm();
  const [ createCategory ] = categoryApi.useCreateCategoryMutation();
  const [ createCategoryWithImage ] = categoryApi.useCreateCategoryWithImageMutation();
  const [imageFile, setImageFile] = useState(null);
  const [  selectedImage ,setSelectedImage] = useState(null);

//  handle add category function
  const handleAddCategory = async(data) => {
    try{
      if(!imageFile){
        const res = await createCategory(data);
        if(res?.data?.success){
            reset();
            toast.success(res?.data?.message)
        }
      } else {
        const formData = new FormData();

        formData.append("name", data?.name);
        formData.append("image", imageFile);
        formData.append("type", data?.type);
        formData.append("slug", data?.slug);
        formData.append("description", data?.description);

        const res = await createCategoryWithImage(formData);
        if(res?.data?.success){
          reset();
          toast.success(res?.data?.message);
        }
      }

    } catch(error){
        toast.error(error?.data?.message)
        console.log(error)
    }

  };



      // Handle image change function
  const handleImageChange = (files) => {
        if (files && files.length > 0) {
          const imageFile = files[0];
          setImageFile(imageFile);
    
          const imageUrl = URL.createObjectURL(imageFile);
          setSelectedImage(imageUrl);
        }
      };

      // Handle image delete function
  const handleImageDelete = () => {
    setSelectedImage(null);
    document.querySelector("#image").value = null;
  };


  return (
    <div className=" container bg-white shadow-lg rounded-lg p-6 mt-3">
       <div className=" flex justify-between px-5">
       <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Add Category</h2>
       <Link to={"/dashboard/categories"} className="text-xl font-bold text-center text-white mb-6  px-2 py-1 bg-blue-500 rounded ">All Categories</Link>

       </div>
      <form onSubmit={handleSubmit(handleAddCategory)} className=" max-w-lg mx-auto">
        {/* Category Name */}
        <div className="mb-4 grid grid-cols-4 gap-2">
          <label htmlFor="categoryName" className="block text-gray-700 font-medium mb-2 col-span-1">
            Category Name
          </label>
          <input
            id="name"
            type="text"
            {...register("name", { required: "Category Name is required" })}
            className={`w-full px-3 py-1 border col-span-3  ${
              errors.categoryName ? "border-red-500" : "border-gray-300"
            } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            placeholder="Enter category name"
          />
          {errors.categoryName && (
            <p className="text-red-500 text-sm mt-1">{errors.categoryName.message}</p>
          )}
        </div>

        {/* Description */}
        <div className="mb-4 grid grid-cols-4 gap-2">
          <label htmlFor="description" className="block text-gray-700 font-medium mb-2 col-span-1">
            Description
          </label>
          <textarea
            id="description"
            {...register("description", { required: "Description is required" })}
            className={`w-full px-3 py-1 border col-span-3 ${
              errors.description ? "border-red-500" : "border-gray-300"
            } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            placeholder="Enter category description"
            rows="3"
          ></textarea>
          {errors.description && (
            <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>
          )}
        </div>

        {/* Slug */}
        <div className="mb-4 grid grid-cols-4 gap-2">
          <label htmlFor="slug" className="block text-gray-700 font-medium mb-2 col-span-1">
            Slug
          </label>
          <input
            id="slug"
            type="text"
            {...register("slug", {
              required: "Slug is required",
              pattern: {
                value: /^[a-z0-9-]+$/,
                message: "Slug must contain only lowercase letters, numbers, and hyphens",
              },
            })}
            className={`w-full px-3 py-1 border col-span-3 ${
              errors.slug ? "border-red-500" : "border-gray-300"
            } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            placeholder="Enter slug"
          />
          {errors.slug && <p className="text-red-500 text-sm mt-1">{errors.slug.message}</p>}
        </div>

        {/* Category Type */}
        <div className="mb-4 grid grid-cols-4 gap-2">
          <label htmlFor="type" className="block text-gray-700 font-medium mb-2 col-span-1">
            Category Type
          </label>
          <select
            id="type"
            {...register("type", { required: "Category Type is required" })}
            className={`w-full px-3 py-1 border col-span-3 ${
              errors.type ? "border-red-500" : "border-gray-300"
            } rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
          >
            <option value="">Select a category type</option>
            <option value="event">Event</option>
            <option value="blog">Blog</option>
            <option value="e-resources">E-resources</option>
          </select>
          {errors.type && (
            <p className="text-red-500 text-sm mt-1">{errors.type.message}</p>
          )}
        </div>

        <div className=" mt-3">
           <label htmlFor="image" className="block text-sm font-medium text-gray-700">
                Category image <span className=" text-red-500 ">{"*"}</span>
           </label>

        <div className="cursor-pointer mt-3 p-3 border-dashed border-2 border-gray-300 bg-gray-50 dark:bg-gray-600 rounded-md flex items-center justify-center relative">
          {selectedImage ? (
            <>
              <img
                src={selectedImage}
                alt="Selected"
                className="w-full h-[250px] object-cover rounded-md"
              />
              <button
                onClick={handleImageDelete}
                className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full focus:outline-none hover:bg-red-600"
              >
                <AiOutlineClose className="text-lg" />
              </button>
            </>
          ) : (
            <span
              onClick={() => document.querySelector("#image").click()}
              className="flex flex-col items-center h-[230px] "
            >
              <span className="text-3xl dark:text-gray-300 text-gray-400">
                📁
              </span>
              <p className="mt-2 text-sm dark:text-gray-300 text-gray-500 p-2 border rounded-md">
                Select Image
              </p>
            </span>
          )}
        </div>

        <input
          type="file"
          id="image"
          accept="image/*"
          className="hidden"
          {...register("image", { required: true })}
          onChange={(e) => handleImageChange(e.target.files)}
        />
        {errors.image && (
          <span className="text-red-500">This field is required</span>
        )}

        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Add Category
        </button>
      </form>
    </div>
  );
};

export default AddCategory;
